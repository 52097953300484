@charset "utf-8";

$base-font-family: Liberation Serif, DejaVu Serif, Bitstream Vera Serif, serif;
$base-font-size:   20px;
$small-font-size:  $base-font-size * 0.95;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fafafa;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$on-palm:          600px;
$on-laptop:        900px;

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
